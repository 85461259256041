export const GET_USER = '/v1/accounts/user'
export const SEND_OTP = '/v1/accounts/otp'
export const VERIFY_OTP = '/v1/accounts/otp/verify'
export const GET_ACCOUNT = '/v1/accounts'
export const GET_CLAIMED_COLLECTIBLES = '/v1/collectibles'
export const GET_LOCALITY = '/v1/accounts/locality'
export const GET_SETTINGS = '/v1/wati/settings'

export const CHECK_TOKEN = '/v1/accounts/expiry'

export const GET_COLLECTION_WITH_ID = collectionId =>
  `/v1/collections/${collectionId}`

export const GET_COLLECTION_COLLECTIBLES = collectionId => {
  return `/v1/collections/${collectionId}/collectibles`
}

export const PAY_COLLECTION = collectionId =>
  `/v1/collections/${collectionId}/purchase`
export const MINT_COLLECTION = collectionId =>
  `/v1/collections/${collectionId}/mint`

export const COLLECTION_PRIVILEGES = `/v1/collections/privileges`

export const GET_AIRDROP_WITH_ID = airdropId => `/v1/airdrops/${airdropId}`
export const PAY_AIRDROP = airdropId => `/v1/airdrops/${airdropId}/purchase`
export const MINT_AIRDROP = airdropId => `/v1/airdrops/${airdropId}/mint`

export const GET_COLLECTIBLE_WITH_ID = collectibleId =>
  `/v1/collectibles/${collectibleId}/details`
export const RENEW_COLLECTIBLE_WITH_ID = collectibleId =>
  `/v1/collectibles/${collectibleId}/renew`
export const GET_COLLECTIBLE_RECEIPT = tokenId =>
  `/v1/collectibles/${tokenId}/receipt`
export const GET_COLLECTIBLE_APPLE_WALLET_PASS = tokenId =>
  `/v1/collectibles/${tokenId}/applepass`
export const ADD_COLLECTIBLE_CHECKIN = tokenId =>
  `/v1/collectibles/${tokenId}/checkins`
export const GET_CHECKINS =  `/v1/collectibles/checkins`  

export const PRESIGN_UPLOAD_URL = '/v1/s3/uploads'

export const LEASE_COLLECTIBLE_PURCHASE = tokenId =>
  `/v1/leases/${tokenId}/purchase`
export const LEASE_COLLECTIBLE = tokenId => `/v1/leases/${tokenId}/claim`
export const LEASE_COLLECTIBLE_LIST = tokenId => `/v1/leases/${tokenId}`

export const GET_LEASE_DETAILS = leaseId => `/v1/leases/${leaseId}/details`
export const LEASE_COLLECTIBLE_FROM_COLLECTION = collectionId =>
  `/v1/collectibles/${collectionId}/lease`

export const BRAND_CONTACT_FORM =
  'https://script.google.com/macros/s/AKfycbxJWBaIPvSf7aXN3dx-PYhRXN667RMeWJ1aaq5_lsl3q7jiGGIn7DEsLWlvCGdBAw_1/exec'

export const GET_BRAND_DETAILS = `/v1/brands`
export const ADD_USER_TO_WAITLIST = `/v1/brands/waitlist`

export const GET_WALLET_HISTORY = `/v1/accounts/wallet_history`
export const GET_WALLET_BALANCE = `/v1/accounts/wallet_balance`
export const ADD_PAN = `/v1/accounts/pan`
export const ADD_BANK = `/v1/accounts/bank`
export const GET_ACCOUNTS_BY_ID = accountId => `/v1/accounts/${accountId}`
export const GET_ACCOUNTS_BY_PHONE = `/v1/accounts`

export const CREATE_PAYOUT_TRANSFER = `/v1/payouts/transfers`
export const SEARCH_USER = `/v1/accounts/search`

export const GET_BANK_BY_IFSC_CODE = ifscCode =>
  `https://ifsc.razorpay.com/${ifscCode}`

export const PAYTM_BANKS_URL =
  'https://trust.paytm.in/wallet-web/wrapper/getList?channel=web&child_site_id=1&site_id=1&version=2'
